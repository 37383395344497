import { HamburgerIcon } from '@chakra-ui/icons'
import {
  Flex,
  Heading,
  Link,
  useBreakpointValue,
  useDisclosure,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
} from '@chakra-ui/react'

import { s3ImageURL } from '@miimosa/common'
import { Icon } from '@miimosa/design-system'

const Header = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' })
  const { isOpen, onOpen, onClose } = useDisclosure()

  return isMobile ? (
    <Flex
      w="full"
      bg="white"
      position="sticky"
      top={0}
      zIndex={10}
      direction="row"
      h="70px"
      justifyContent="space-between"
      px={10}
      alignItems="center"
    >
      <Icon name="LesieurLogo" size="2xs" h="auto" />
      <IconButton aria-label="Button to open menu" onClick={onOpen} icon={<HamburgerIcon boxSize={8} />} />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
        <DrawerOverlay />
        <DrawerContent py={6} backgroundImage={s3ImageURL('bg-form.png')} backgroundRepeat="repeat">
          <DrawerCloseButton />
          <DrawerBody display="flex" rowGap={4} flexDirection="column">
            <Link href="#form" onClick={onClose}>
              <Heading size="md" color="gray" whiteSpace="nowrap">
                Je candidate
              </Heading>
            </Link>
            <Link href="#ambition" onClick={onClose}>
              <Heading size="md" color="gray">
                L&apos;ambition de Lesieur
              </Heading>
            </Link>
            <Link href="#mission" onClick={onClose}>
              <Heading size="md" color="gray">
                La démarche &quot;Lesieur huiles engagées&quot;
              </Heading>
            </Link>
            {/* <Link href="#projects" onClick={onClose}>
              <Heading size="md" color="gray">
                Les projets
              </Heading>
            </Link> */}
            <Link href="#faq" onClick={onClose}>
              <Heading size="md" color="gray">
                Faq
              </Heading>
            </Link>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  ) : (
    <Flex
      w="full"
      py={2}
      px={{ base: 10, xl: 20 }}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      bg="white"
      position="sticky"
      top={0}
      zIndex={10}
    >
      <Icon name="LesieurLogo" size="sm" h="auto" />
      <Flex direction="row" columnGap={10} alignItems="center">
        <Link href="#form" bg="green.800" width="fit-content" py={2} px={4} borderRadius="md">
          <Heading color="white" size="md" whiteSpace="nowrap">
            Je candidate
          </Heading>
        </Link>
        <Link href="#ambition">
          <Heading size="md" color="gray">
            L&apos;ambition de Lesieur
          </Heading>
        </Link>
        <Link href="#mission">
          <Heading size="md" color="gray">
            La démarche &quot;Lesieur huiles engagées&quot;
          </Heading>
        </Link>
        {/* <Link href="#projects">
          <Heading size="md" color="gray">
            Les projets
          </Heading>
        </Link> */}
        <Link href="#faq">
          <Heading size="md" color="gray">
            Faq
          </Heading>
        </Link>
      </Flex>
    </Flex>
  )
}

export default Header
